@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  background: #232222 0% 0% no-repeat padding-box;
  background: #232222 0% 0% no-repeat padding-box;
  opacity: 1;
  color: white;
  min-height: 100vh;
  max-width: 100%;
  overflow: hidden;
}
h1 {
  color: white;
  text-align: center;
  font: normal normal 800 47px/55px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

h4 {
  color: white;
  text-align: center;
  font: normal normal 600 18px/30px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  text-align: center;
  font: normal normal normal 17px/22px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
